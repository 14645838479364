$tablet-width: 1280px
$tablet-mini-width: 1079px
$mobile-width: 767px
$mini-width: 425px

$tomato: #DC3E22

$pink: #EA8979
$light-red: #EE735D
$red: #DC3E22
$dark-red: #B7280F

$green: #A8B64F
$dark-green: #899441

$orange: #FFDDA9
$dark-orange: #FFAE35

$purple: #DFDCFE
$dark-purple: #9C97D7

$blue: #C5F1FF
$dark-blue: #7FC2D7

$white: hsl(0, 0%, 100%)
$greyF4: hsl(0, 0%, 96%)
$greyF3: hsl(0, 0%, 95%)
$greyEC: hsl(0, 0%, 93%)
$greyE5: hsl(0, 0%, 90%)
$greyD9: hsl(0, 0%, 85%)
$greyC4: hsl(0, 0%, 77%)
$grey99: hsl(0, 0%, 60%)
$grey66: hsl(0, 0%, 40%)
$black: hsl(0, 0%, 20%)

$i-tomato: #23C1DD

$i-pink: #157686
$i-light-red: #118ca2
$i-red: #23c1dd
$i-dark-red: #48d7f0

$i-green: #5749b0
$i-dark-green: #766bbe

$i-orange: #002256
$i-dark-orange: #0051ca

$i-purple: #202301
$i-dark-purple: #636828

$i-blue: #3a0e00
$i-dark-blue: #803d28

$i-white: hsl(0, 0%, 0%)
$i-greyF4: hsl(0, 0%, 4%)
$i-greyF3: hsl(0, 0%, 5%)
$i-greyEC: hsl(0, 0%, 7%)
$i-greyE5: hsl(0, 0%, 10%)
$i-greyD9: hsl(0, 0%, 15%)
$i-greyC4: hsl(0, 0%, 23%)
$i-grey99: hsl(0, 0%, 40%)
$i-grey66: hsl(0, 0%, 60%)
$i-black: hsl(0, 0%, 80%)



