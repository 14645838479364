.iconcontainer
  position: absolute

.icon
  filter: drop-shadow(0px 0px 1px #000) drop-shadow(0px 0px 2px #000) drop-shadow(0px 0px 3px #fff) drop-shadow(0px 0px 3px #fff)

.arrow
  position: absolute

.arrow1
  fill: #df0b00

.arrow2
  fill: #601f93

.arrow3
  fill: #ffd231

.arrow4
  fill: #008127
