@import '../../variables.global'
@import '../../mixins.global'

.compass
  position: absolute
  top: 10px
  left: 10px
  @include flex-center()
  opacity: 0.7

.dirname
  font-weight: bolder
  text-align: center
  vertical-align: center
  position: absolute
  margin: auto

.rose1
  position: relative

.rose
  @include offset(0, 0, 0, 0)