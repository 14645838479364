@import '../../../variables.global'
@import '../../../mixins.global'

.btn
  padding: 5px 5px
  font-family: 'Lato', sans-serif
  font-size: 50px
  font-weight: 500
  background: transparent
  cursor: pointer
  display: inline-block
  outline: none
  line-height: 22px
  border: none
  position: absolute
  top: 120px
  right: 0
  border-radius: 0

.icon
  fill: #ff8080
  opacity: 0.6


.btn:hover .icon
  opacity: 0.8