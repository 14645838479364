.hex
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background-size: 150%
  background-position: center center
  border: solid 0px transparent
  -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)
  z-index: 2
  cursor: pointer
  color: white
  text-shadow: 0px 0px 5px black

.shadow
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)

.shadow:hover
  filter: drop-shadow(0px 0px 3px yellow)

