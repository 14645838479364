@import '../../mixins.global'

.startscreen
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  height: 100%

.welcome
  text-align: center

.exitbtn
  position: absolute!important
  top: 0
  right: 0
  border-radius: 0px!important

.namebtn
  position: absolute!important
  bottom: 0
  border-radius: 0px!important

.authbtns
  @include flex-center()
