@keyframes jump
  0%
    transform: scale(1,1) translateY(0)
  10%
    transform: scale(1.1,.9) translateY(0)
  30%
    transform: scale(.9,1.1) translateY(-10px)
  50%
    transform: scale(1.05,.95) translateY(0)
  57%
    transform: scale(1,1) translateY(-7px)
  64%
    transform: scale(1,1) translateY(0)
  100%
    transform: scale(1,1) translateY(0)

.unit
  filter: drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 3px  black) drop-shadow(0px 0px 6px  black)
  position: relative

.unit1
  fill: #df0b00

.unit2
  fill: #e76801

.unit3
  fill: #ffd231

.unit4
  fill: #008127

.unit5
  fill: #67c9d6

.unit6
  fill: #004c94

.unit7
  fill: #601f93

.unit8
  fill: #a01280

.active
  filter: drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 2px  white) drop-shadow(0px 0px 2px white)

.gold
  filter: drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 2px  gold) drop-shadow(0px 0px 2px gold)

.hoverunit
  filter: drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 3px white) drop-shadow(0px 0px 3px white)
  cursor: pointer

.money
  background-color: gold
  fill: gold
  border: solid 1px #282c34
  position: absolute
  bottom: 0
  right: 0
  z-index: 22

.jump
  animation-duration: 2s
  animation-iteration-count: infinite
  animation-name: jump
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1)
