.field
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background-size: 150%
  background-position: center center
  border: solid 0 transparent
  z-index: 2
  cursor: pointer
  color: white
  text-shadow: 0 0 5px black

.hex
  -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)

.square
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)

.shadow
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

.shadowhover:hover
  filter: drop-shadow(0px 0px 3px white) drop-shadow(0px 0px 3px white) drop-shadow(0px 0px 3px white) drop-shadow(0px 0px 3px white)

.allowedfield
  filter: drop-shadow(0px 0px 3px white) drop-shadow(0px 0px 3px white)