@import '../../../variables.global'
@import '../../../mixins.global'

.circle
  width: 100%
  height: 100%
  padding: 0
  border-radius: 50%
  list-style: none
  box-sizing: content-box
  position: absolute
