.scalebtns
  position: absolute
  left: 3px
  bottom: 3px
  display: block
  width: 80px
  height: 80px
  margin: auto

.btn
  border-radius: 5px
  background: transparent
  color: #ffffff66
  position: absolute
  cursor: pointer
  right: 4px

.btn:hover
  background: transparent

.btnplus
  border-top: dashed #ffffff66 6px
  border-right: dashed #ffffff66 6px
  box-sizing: border-box
  width: 90%
  height: 90%
  right: 0
  top: 3px
  z-index: 1

.arrow
  position: absolute
  fill: #ffffff66
  top: 7%
  right: 7%
  left: auto!important
  bottom: auto!important
  width: 48%
  height: 48%

.btnplus::after, .btnplus::before
  box-sizing: border-box
  position: absolute
  content: ''
  display: block
  width: 40%
  height: 40%

.btnplus::before
  border: none
  border-left: dashed #ffffff66 6px
  left: 0
  top: 3px

.btnplus::after
  border: none
  border-bottom: dashed #ffffff66 6px
  right: 3px
  bottom: 0

.btnplus:hover
  border-top: solid #ffffff66 6px
  border-right: solid #ffffff66 6px
  &::before
    border-left: solid #ffffff66 6px
  &::after
    border-bottom: solid #ffffff66 6px

.btnminus
  border: dashed #ffffff66 6px
  width: 50%
  height: 50%
  left: 0
  bottom: 0
  z-index: 2

.btnminus:hover
  border: solid #ffffff66 6px

.btnminus:hover ~ .arrow
  transform: rotate(180deg)

.plus
  position: absolute
  width: 40px
  height: 42px
  top: 0
  right: 0
  color: white
  line-height: 40px

.minus
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  margin: auto
  line-height: 20px
  vertical-align: middle
  display: inline-block
  color: white

.dot
  top: 4px
  right: 4px
  position: absolute
