@keyframes jump
  0%
    transform: scale(1,1) translateY(0)
  10%
    transform: scale(1.1,.9) translateY(0)
  30%
    transform: scale(.9,1.1) translateY(-10px)
  50%
    transform: scale(1.05,.95) translateY(0)
  57%
    transform: scale(1,1) translateY(-7px)
  64%
    transform: scale(1,1) translateY(0)
  100%
    transform: scale(1,1) translateY(0)

.moneyitem
  display: flex
  justify-content: center
  align-items: center
  position: relative
  width: 100%
  height: 100%
  background-color: gold
  box-shadow: 0px 0px 2px #000, 0px 0px 2px black, 0px 0px 6px gold, 0px 0px 8px gold, 0px 0px 10px gold
  border-radius: 50%
  animation-duration: 1000ms
  animation-iteration-count: 1
  animation-name: jump
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1)

.count
  position: absolute
  color: #fff
  text-shadow: 1px 1px darkgoldenrod, -1px -1px darkgoldenrod, 1px -1px darkgoldenrod, -1px 1px darkgoldenrod
  text-align: center
  vertical-align: middle
  font-weight: bold
  font-size: 12px
  line-height: 16px