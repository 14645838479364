@import '../../../variables.global'
@import '../../../mixins.global'

.btn
  padding: 15px 15px
  font-family: 'Lato', sans-serif
  font-size: 50px
  font-weight: 500
  background: transparent
  cursor: pointer
  position: relative
  display: inline-block
  outline: none
  line-height: 42px
  border: none
  border-radius: 70px

.btn > *
  fill: white
  opacity: 0.4

.btn:hover > *
  fill: white
  opacity: 0.6
