@import '../../variables.global'
@import '../../mixins.global'

.circle
  width: 100%
  height: 100%
  padding: 0
  border-radius: 50%
  list-style: none
  box-sizing: content-box
  position: absolute

.item
  position: absolute
  top: 50%
  left: 50%

.ship1
  filter: drop-shadow(0px 0px 1px #df0b00) drop-shadow(0px 0px 1px #df0b00)

.ship2
  filter: drop-shadow(0px 0px 1px #e76801) drop-shadow(0px 0px 1px #e76801)

.ship3
  filter: drop-shadow(0px 0px 1px #ffd231) drop-shadow(0px 0px 1px #ffd231)

.ship4
  filter: drop-shadow(0px 0px 1px #008127) drop-shadow(0px 0px 1px #008127)

.ship5
  filter: drop-shadow(0px 0px 1px #00e1ff) drop-shadow(0px 0px 1px #00d9ff)

.ship6
  filter: drop-shadow(0px 0px 1px #0082ff) drop-shadow(0px 0px 1px #0084ff)

.ship7
  filter: drop-shadow(0px 0px 1px #9000ff) drop-shadow(0px 0px 1px #9200ff)

.ship8
  filter: drop-shadow(0px 0px 1px #ff00c6) drop-shadow(0px 0px 1px #ff00cb)

.active
  filter: drop-shadow(0px 0px 2px white) drop-shadow(0px 0px 3px white)