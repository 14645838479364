.squaremap
  position: relative

.squarebutton
  position: absolute

.hexmap
  position: relative

.hexbutton
  position: absolute

.row
  display: flex
  gap: 3px
  justify-content: center

.earth
  background-image: url('../../../../public/img/map.jpg')

.sea
  background-color: #ffffff22
  backdrop-filter: blur(2px)
  fill: #ffffff
  opacity: 0.1

.sea1
  background-color: #df0b0099
  backdrop-filter: blur(2px)
  fill: #df0b00
  opacity: 0.1

.sea2
  background-color: #e7680199
  backdrop-filter: blur(2px)
  fill: #e76801
  opacity: 0.1

.sea3
  background-color: #ffd23199
  backdrop-filter: blur(2px)
  fill: #ffd231
  opacity: 0.1

.sea4
  background-color: #00812799
  backdrop-filter: blur(2px)
  fill: #008127
  opacity: 0.1

.sea5
  background-color: #67c9d699
  backdrop-filter: blur(2px)
  fill: #67c9d6
  opacity: 0.1

.sea6
  background-color: #004c9499
  backdrop-filter: blur(2px)
  fill: #004c94
  opacity: 0.1

.sea7
  background-color: #601f9399
  backdrop-filter: blur(2px)
  fill: #601f93
  opacity: 0.1

.sea8
  background-color: #a0128099
  backdrop-filter: blur(2px)
  fill: #a01280
  opacity: 0.1

.ship
  cursor: pointer

.ship1
  fill: #df0b00

.ship2
  fill: #e76801

.ship3
  fill: #ffd231

.ship4
  fill: #008127

.ship5
  fill: #67c9d6

.ship6
  fill: #004c94

.ship7
  fill: #601f93

.ship8
  fill: #a01280

.active
  filter: drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 1px white)
  cursor: pointer