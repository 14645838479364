.scale
  position: absolute
  right: 6px
  bottom: 6px
  margin: auto

.shapebtns
  display: flex
  align-items: center
  justify-content: space-between
  flex-direction: column
  border: dashed #ffffff66 6px
  border-radius: 40px 10px 40px 10px
  box-sizing: border-box

.shapebtn
  background-color: white
  opacity: 0.6
  display: flex
  align-items: center
  justify-content: center

.shapebtns > div
  min-width: 40px
  height: 40px
  position: relative

.active
  background-color: greenyellow

.menu
  position: absolute
  margin: auto
  bottom: 0
  left: 0
  right: 0
  display: flex
  align-items: center
  justify-content: center
  overflow: hidden

.earthshipbtns
  height: 80px
  display: flex
  align-items: center
  justify-content: center
  flex-direction: row
  gap: 20px
  box-sizing: border-box

.earthshipbtns > div
  width: 80px
  height: 80px
  position: relative

.earthshipbtns > div > div
  cursor: pointer
  position: absolute
  bottom: 3px

.earthshipbtns > div > div:first-child
  top: 0
  right: 0

.earthshipbtns > div > div:last-child
  bottom: 0
  left: 0

.shovelbtn
  fill: white
  opacity: 0.6
  display: flex
  align-items: center
  justify-content: center

.activeshovel
  fill: greenyellow

.shovel
  transform: rotate(90deg)

.deleteship
  fill: #ff8080
  opacity: 0.6
  position: absolute
  top: -8px
  right: -3px

.deleteship:hover
  opacity: 0.8

.scalemulti, .scalex
  position: relative
  width: 80px

.scalemulti > div, .scalex > div
  box-sizing: border-box

.choose
  fill: #80ff80
  opacity: 0.6
  position: absolute
  top: 8px
  right: 70px

