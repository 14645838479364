.notifybox
  width: 300px
  height: auto
  box-sizing: border-box
  position: absolute
  right: 10px
  bottom: 0
  display: flex
  justify-content: flex-end
  align-items: center
  flex-direction: column

