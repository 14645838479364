.labyrinthitem
  display: flex
  justify-content: center
  align-items: center
  position: relative
  width: 100%
  height: 100%
  background-color: white
  border-radius: 50%
  box-shadow: 0 0 2px #000, 0 0 4px #000, 0 0 6px #fff, 0 0 8px #fff
  opacity: 0.9

.count
  position: absolute
  color: black
  text-align: center
  vertical-align: middle
  text-shadow: none
  font-weight: bold
  font-size: 12px
  line-height: 16px


.labyrinth1
  background-color: #df0b00

.labyrinth2
  background-color: #ff6200

.labyrinth3
  background-color: #ffd231

.labyrinth4
  background-color: #008127

.labyrinth5
  background-color: #00e1ff