@import '../../mixins.global'

.curtain
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: #00000099
  z-index: 9999

.window
  display: flex
  width: 100%
  height: 100%
  @include flex-center()

.close
  padding: 5px
  margin: 3px
  margin-bottom: 10px
  float: right