@import '../../variables.global'
@import '../../mixins.global'

.choosegame
  @include flex-space(between, column)
  max-height: 66vh

.choosegameitem
  display: inline-flex
  align-items: center
  justify-content: space-between
  column-gap: 10px

.icon
  fill: #3a0e00

.mapchooseitem
  border-radius: 50%
  width: 50px
  height: 50px
  display: flex
  justify-content: center
  align-items: center

.mapchoosebutton
  background-image: url('../../../public/img/island.jpg')

.onoff
  position: absolute!important
  top: 0
  left: 0
  border-radius: 0px!important