.square
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  position: relative
  background-size: 150%
  background-position: center center
  border: solid 0px transparent
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)
  z-index: 2
  cursor: pointer
  color: white
  text-shadow: 0px 0px 5px black

.shadow:hover
  filter: drop-shadow(0px 0px 3px yellow)