@mixin font($font-size: 16, $line-height: 17, $font-weight: 400, $font-style: normal)
  font-size: $font-size+px
  line-height: $line-height+px
  font-weight: $font-weight
  font-style: $font-style

@mixin flex-center($direction: row)
  display: flex
  justify-content: center
  align-items: center
  flex-direction: $direction

@mixin flex-space($space: between, $direction: row)
  display: flex
  flex-direction: $direction
  justify-content: space-+$space

@mixin size($width: auto, $height: auto, $unit: px)
  @if $width == auto
    width: $width
  @else if $width == full
    width: 100%
  @else
    width: $width+$unit
  @if $height == auto
    height: $height
  @else if $height == full
    height: 100%
  @else
    height: $height+$unit

@mixin offset($top: false,$right: false, $bottom: false, $left: false)
  position: absolute
  margin: auto
  @if $top
    top: $top+px
  @if $right
    right: $right+px
  @if $bottom
    bottom: $bottom+px
  @if $left
    left: $left+px

@mixin wrapper($ratio: 50%)
  position: relative
  height: 0
  border: none
  padding-top: $ratio

@mixin tablet
  @media (max-width: $tablet-width)
    @content

@mixin tablet-mini
  @media (max-width: $tablet-mini-width)
    @content

@mixin mobile
  @media (max-width: $mobile-width)
    @content

@mixin mini
  @media (max-width: $mini-width)
    @content

@mixin distribute-on-circle($nb-items, $circle-size, $item-size)
  $half-item: ($item-size / 2)
  $half-parent: ($circle-size / 2)
  position: relative
  width: 100%
  height: 100%
  padding: 0
  border-radius: 50%
  list-style: none
  box-sizing: content-box
  > *
    display: block
    position: absolute
    top: 50%
    left: 50%
    width: $item-size
    height: $item-size
    margin: -$half-item

  $angle: (360 / $nb-items)
  $rot: 0
  @for $i from 1 through $nb-items
    >:nth-of-type(#{$i})
      transform: rotate($rot * 1deg) translate($half-parent) rotate($rot * -1deg)

    $rot: ($rot + $angle)
