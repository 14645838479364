@import '../../../variables.global'
@import '../../../mixins.global'

.btn
  padding: 5px 5px
  font-family: 'Lato', sans-serif
  font-size: 50px
  font-weight: 500
  background: transparent
  cursor: pointer
  display: inline-block
  outline: none
  line-height: 22px
  border: none
  position: absolute
  top: 60px
  right: 0

.icon
  fill: white
  opacity: 0.4


.btn:hover .icon
  opacity: 0.6