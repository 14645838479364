.vkurl
  display: inline-block
  padding: 15px 15px
  border-radius: 70px

.vkurl > *
  fill: white
  opacity: 0.4

.vkurl:hover > *
  fill: white
  opacity: 0.6