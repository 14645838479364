.scale
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  overflow: hidden

.scroll
  display: flex
  justify-content: center
  align-items: center
  min-height: 100vh
  min-width: 100vw
  overflow: hidden

.scroll > div
  overflow: hidden