.creategame
  display: inline-flex
  flex-wrap: wrap
  align-items: center
  justify-content: space-between

.creategameitem
  display: inline-flex
  align-items: center
  justify-content: space-between
  column-gap: 10px

.icon
  fill: #3a0e00

.mapchooseitem
  border-radius: 50%
  width: 50px
  height: 50px
  display: flex
  justify-content: center
  align-items: center

.mapchoosebutton
  background-image:  url('../../../public/img/island.jpg')

.arrowicon
  fill: white
  opacity: 0.6

.exitbtn
  position: absolute!important
  top: 0
  right: 0
  border-radius: 0px!important
  border-bottom-left-radius: 50px!important

.editmode
  position: absolute!important
  bottom: 0
  right: 0
  fill: white
  opacity: 0.4
  margin: 5px
  transform: rotate(180deg)