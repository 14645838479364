@import '../../mixins.global'

.field
  position: relative
  @include flex-center()

.top
  width: 100%
  height: 100%
  padding: 0
  border-radius: 50%
  list-style: none
  box-sizing: content-box
  position: absolute

.item
  position: absolute
  top: 50%
  left: 50%

.score
  text-align: center
  vertical-align: middle

.bottom
  width: 100%
  height: 100%
  padding: 0
  border-radius: 50%
  list-style: none
  box-sizing: content-box
  position: absolute

