@import '../../../mixins.global'

.playership
  @include flex-center()
  height: 100%

.playername
  color: black
  font-weight: bolder
  font-size: 12px
  z-index: 10

.ship1
  filter: drop-shadow(0px 0px 1px #df0b00) drop-shadow(0px 0px 1px #df0b00)
  fill: #4d0000

.ship2
  filter: drop-shadow(0px 0px 1px #e76801) drop-shadow(0px 0px 1px #e76801)
  fill: #863800

.ship3
  filter: drop-shadow(0px 0px 1px #ffd231) drop-shadow(0px 0px 1px #ffd231)
  fill: #7a6100

.ship4
  filter: drop-shadow(0px 0px 1px #008127) drop-shadow(0px 0px 1px #008127)
  fill: #004f18

.ship5
  filter: drop-shadow(0px 0px 1px #67c9d6) drop-shadow(0px 0px 1px #67c9d6)
  fill: #005e6c

.ship6
  filter: drop-shadow(0px 0px 1px #004c94) drop-shadow(0px 0px 1px #004c94)
  fill: #002956

.ship7
  filter: drop-shadow(0px 0px 1px #601f93) drop-shadow(0px 0px 1px #601f93)
  fill: #300052

.ship8
  filter: drop-shadow(0px 0px 1px #a01280) drop-shadow(0px 0px 1px #a01280)
  fill: #4d003c

.active
  filter: drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 1px white)
  cursor: pointer

.inactive
  opacity: 0.5

.rolling
  animation-duration: 5s
  animation-iteration-count: infinite
  animation-name: rolling
  animation-timing-function: linear