.notify
  width: 100%
  height: auto
  padding: 10px 8px 10px 8px
  border-radius: 0px
  border-left: solid 3px gray
  box-shadow: 3px 3px 3px black
  background-color: white
  margin: 0px 3px 10px
  display: grid
  grid-template-columns: 20px 1fr 20px
  align-items: flex-start
  box-sizing: border-box
  opacity: 0.9

  &:hover
    opacity: 1

.textbox
  font-family: roboto,Arial,sans-serif
  height: 100%
  background-color: transparent
  &> h3
    margin: 2px 5px 10px 10px
    font-size: 14px
  &> p
    margin: 5px 5px 5px 10px
    font-size: 12px

.success
  background-color: #e7f4e8
  border-left: solid 2px #83c886
  & button *
    fill: #83c886

.warning
  background-color: #fff1dc
  border-left: solid 2px #ffb84c
  & button *
    fill: #ffb84c

.error
  background-color: #ffe8e1
  border-left: solid 2px #ff8861
  & button *
    fill: #ff8861

.info
  background-color: #e1effb
  border-left: solid 2px #62aeee
  & button *
    fill: #62aeee
