.mapeditor
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  overflow: hidden

.scale
  position: absolute
  right: 0
  bottom: 0
  top: 0
  margin: auto

.scaley
  position: absolute
  left: 0
  right: auto
  bottom: 50%
  top: 0
  margin: auto

.scalex
  position: absolute
  left: 0
  right: auto
  bottom: 0
  margin: auto


.playerposchange
  position: absolute
  bottom: 0
  width: auto
  margin: auto

.maplist
  display: flex
  flex-wrap: wrap

.hexmap
  margin-left: -120px

.mapcard
  background-color: #ebcf90
  padding: 20px
  margin: 10px
  cursor: pointer

.mapbox
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.baseplacebtn
  width: 50px
  height: 50px
  border-radius: 50px
  background-color: gray

.baseplacebtnactive
  background-color: dodgerblue

.editbtns
  position: absolute
  bottom: 0
  left: 0

.deletebtns
  position: absolute
  top: 0
  left: 0
