@import '../variables.global'
@import '../mixins.global'

.pages
  box-sizing: border-box
  padding: 0
  margin: 0
  height: 100vh
  width: 100vw
  position: relative

.blur
  background: #ffffff33
  backdrop-filter: blur(1px)
  height: 100vh
  width: 100vw