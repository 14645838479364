.field
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  height: 100%
  width: 100%

.inactive
  opacity: 0.6

.countplayer
  display: flex
  flex-wrap: wrap
  margin: 5px 20px
  justify-content: center
  align-items: center
  position: relative

.countplayer > div
  margin: 2px

.icon
  fill: white
  opacity: 0.5
  -webkit-filter: drop-shadow( 0px 0px 3px rgba(0, 0, 0, .7))
  filter: drop-shadow( 0px 0px 3px rgba(0, 0, 0, .7))

.occupied
  fill: white
  opacity: 1

.header
  text-align: center

.circle
  position: absolute

.active
  fill: white
  opacity: 1

.earth
  background-image: url('../../../public/img/island.jpg')

.ship1
  filter: drop-shadow(0px 0px 1px #df0b00) drop-shadow(0px 0px 1px #df0b00)

.ship2
  filter: drop-shadow(0px 0px 1px #e76801) drop-shadow(0px 0px 1px #e76801)

.ship3
  filter: drop-shadow(0px 0px 1px #ffd231) drop-shadow(0px 0px 1px #ffd231)

.ship4
  filter: drop-shadow(0px 0px 1px #008127) drop-shadow(0px 0px 1px #008127)

.ship5
  filter: drop-shadow(0px 0px 1px #67c9d6) drop-shadow(0px 0px 1px #67c9d6)

.ship6
  filter: drop-shadow(0px 0px 1px #004c94) drop-shadow(0px 0px 1px #004c94)

.ship7
  filter: drop-shadow(0px 0px 1px #601f93) drop-shadow(0px 0px 1px #601f93)

.ship8
  filter: drop-shadow(0px 0px 1px #a01280) drop-shadow(0px 0px 1px #a01280)

.active
  filter: drop-shadow(0px 0px 2px white) drop-shadow(0px 0px 3px white)

.ship
  fill: white
  opacity: 0.6
