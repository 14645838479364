@keyframes unitrolling
  0%
    transform: rotate(0deg) translateY(0)
  14%
    transform: rotate(0deg) translateY(-1px)
  25%
    transform: rotate(0deg) translateY(-3px)
  39%
    transform: rotate(0deg) translateY(-5px)
  50%
    transform: rotate(0deg) translateY(-7px)
  64%
    transform: rotate(0deg) translateY(-5px)
  75%
    transform: rotate(0deg) translateY(-3px)
  89%
    transform: rotate(0deg) translateY(-1px)
  100%
    transform: rotate(0deg) translateY(0)

@keyframes shiprolling
  0%
    transform: rotate(0deg) translateY(0)
  14%
    transform: rotate(-3deg) translateY(-1px)
  25%
    transform: rotate(-5deg) translateY(-3px)
  39%
    transform: rotate(-3deg) translateY(-5px)
  50%
    transform: rotate(0deg) translateY(-7px)
  64%
    transform: rotate(3deg) translateY(-5px)
  75%
    transform: rotate(5deg) translateY(-3px)
  89%
    transform: rotate(3deg) translateY(-1px)
  100%
    transform: rotate(0deg) translateY(0)

.nohoverbutton:hover
  filter: none!important
  cursor: default!important

.row
  display: flex
  gap: 3px
  justify-content: center

.earth
  background-image: url('../../../public/img/map.jpg')
  background-size: 110%

.sea
  background-color: #ffffff09

.inactiveship
  opacity: 0.5

.playername
  color: black
  font-weight: bolder
  font-size: 12px
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  position: absolute
  top: -25px
  width: 0px
  height: 0px
  z-index: 10

.unitrolling
  animation-duration: 5s
  animation-iteration-count: infinite
  animation-name: unitrolling
  animation-timing-function: linear

.shiprolling
  animation-duration: 5s
  animation-iteration-count: infinite
  animation-name: shiprolling
  animation-timing-function: linear

.surface
  background-position: center center
  background-repeat: no-repeat
  width: 90%
  height: 90%
  display: flex
  align-items: center
  justify-content: center
  position: absolute

.iconcontainer
  position: absolute
  filter: drop-shadow(0px 0px 1px #000) drop-shadow(0px 0px 2px #000) drop-shadow(0px 0px 3px #fff) drop-shadow(0px 0px 3px #fff)

.arrow1
  fill: #df0b00

.arrow2
  fill: #601f93

.arrow3
  fill: #ffd231

.arrow4
  fill: #00310f