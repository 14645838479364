@import './variables.global'
@import './mixins.global'

html
  display: grid
  height: auto
  min-height: 100%
  position: relative

body, body > div
  height: 100%
  min-height: 100%
  flex: 1
  position: absolute
  top: 0px
  bottom: 0px
  left: 0px
  right: 0px

body
  padding: 0
  margin: 0
  font-family: 'Open Sans', serif
  @include font()

#root
  overflow: hidden