.surface
  background-position: center center
  background-repeat: no-repeat
  width: 90%
  height: 90%
  display: flex
  align-items: center
  justify-content: center
  position: absolute
  overflow: hidden

.surface::after
  content: ''
  display: block
  width: 100%
  height: 100%
  background-color: white
  opacity: 0.3
  border-radius: 50%
