@import "../../variables.global"

.base
  text-shadow: 0px 0px 5px white, 0px 0px 13px white
  font-family: 'Lato', sans-serif

.s28
  font-size: 28px
  line-height: 33px
.s20
  font-size: 20px
  line-height: 23px
.s16
  font-size: 16px
  line-height: 19px
.s14
  font-size: 14px
  line-height: 16px
.s12
  font-size: 12px
  line-height: 14px
.s10
  font-size: 10px
  line-height: 12px

.black
  color: $black
.orange
  color: $orange
.green
  color: $green
.purple
  color: $purple
.white
  color: $white
.greyF4
  color: $greyF4
.greyF3
  color: $greyF3
.greyEC
  color: $greyEC
.greyD9
  color: $greyD9
.greyC4
  color: $greyC4
.grey99
  color: $grey99
.grey66
  color: $grey66

.upperCase
  text-transform: uppercase

.bold
  font-weight: 500